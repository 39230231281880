<!--
 * @version: 20220211
 * @Author: lcx
 * @Date: 2022-09-01 14:31:06
 * @LastEditors: lcx
 * @LastEditTime: 2022-09-01 17:55:51
 * @Description: description
-->
<template>
  <div class="page-box">
    <div class="notice-details">
      <div class="notice-title">天美云用户协议</div>
      <div class="time">2022-09-01</div>
      <div class="notice-cont">
        <!-- 详情图  -->
        <div class='goodsDetail'>
          <div>
            广州天美联盟网络科技有限公司（以下简称“天美公司”）依据本协议的规定为用户提供服务，本协议在用户和天美公司间具有合同上的法律效力。天美公司在此特别提醒用户认真阅读、充分理解本协议各条款，特别是其中所涉及的免除、限制天美公司责任的条款、对用户权利限制条款、争议解决和法律适用等。请用户审慎阅读并选择接受或不接受本协议（未成年人应在监护人陪同下阅读）。除非用户接受本协议所有条款，否则用户无权使用天美公司于本协议下所提供的服务。用户一经注册或使用天美服务即视为对本服务条款的理解和接受。
          </div>
          <div>一、关于“天美”服务协议的确认和接纳</div>
          <div>
            （一）“天美”服务是由天美公司向用户提供的“天美”软件服务系统以及为用户提供的代收代付货款的中介服务（以下简称“天美服务”）。为了保障您的权益，您在自愿注册使用本天美服务前，必须仔细阅读并接受本服务协议所有条款。
          </div>
          <div>
            （二）天美公司有权在必要时单方修改或变更本服务协议之内容，并将通过天美公司网站公布最新的服务协议，不另作个別通知。若您于任何修改或变更本服务条款后继续使用本服务，则视为您已阅读、了解并同意接受修改或变更，若您不同意，则您应主动立即停用本服务。
          </div>
          <div>（三）如您所属的国家或地区排除本服务条款内容的全部或部分，则您应立即停止使用天美服务。</div>
          <div>二、关于“天美”服务的注册</div>
          <div>在使用“天美”服务前，您必须先注册成为“天美”用户，您同意以下事项：</div>
          <div>
            （一）“天美”服务仅向符合中华人民共和国法律规定的具有完全民事权利能力和民事行为能力，能够独立承担民事责任的自然人、法人或其它组织提供。其中香港地区天美用户限符合上述法律规定的自然人用户，且已在中国境内银行开设人民币账户。
          </div>
          <div>（二）您必须依天美服务注册表的提示提供您本人真实、最新及完整的资料。</div>
          <div>
            （三）您有义务维持并立即更新您的“用户资料”，确保其为真实、最新及完整。若您提供任何错误、虚假、过时或不完整的资料，或者天美公司有合理的理由怀疑资料为错误、虚假、过时或不完整，天美公司有权暂停或终止您的用户账户，并拒绝您使用本服务的部份或全部功能，天美公司不承担任何责任，您并同意负担因此所产生的直接或间接的任何支出。
          </div>
          <div>（四）若因您未及时更新基本资料，导致“天美”服务无法提供或提供时发生任何错误，您不得将此作为取消交易或拒绝付款的理由，天美公司亦不承担任何责任，所有后果应由您承担。</div>
          <div>
            （五）为了适用法律规范，本条款及任何其他的协议、告示或其他关于您使用本服务账户及服务的通知，您同意天美公司使用电子方式通知您。电子方式包括但不限于以电子邮件方式、或于本网站或者合作网站上公布、或无线通讯装置通知等方式。
          </div>
          <div>三、关于“天美”服务的使用</div>
          <div>（一）“天美”服务说明：</div>
          <div>1. 一旦您注册成为天美用户，并选择使用“天美”服务，则天美公司将在您及（或）对方符合指定条件或状态时，支付款项给您所指定的对象，或收取他人支付您的款项。</div>
          <div>2.
            您在此确认，您在天美网站或第三方网站上按“天美”服务流程所确认的交易状态将成为天美公司为您支付或收取款项的不可撤销的指令。您同意天美公司有权按相关指令依据天美服务协议及（或）天美服务纠纷处理规则等约定对款项进行处理。
          </div>
          <div>3.如您未能及时对交易状态进行修改或确认或未能提交相关申请所引起的任何纠纷或损失由您本人负责，天美公司不承担任何责任。</div>
          <div>4.您应自行安排货物的交付，并要求买方在收货凭证上签字确认，因您拒绝发货或货物延迟送达或在送达过程中的丢失、毁损，天美公司不承担任何责任，应由交易双方自行处理。</div>
          <div>5. 任何时候天美公司会将您使用天美服务且暂留的资金，绝对独立于天美公司营运资金之外，并不会将您的资金用于非您指示的用途上。</div>
          <div>6. 您了解天美公司并不是银行或金融机构，“天美”服务也并非金融业务，按照中华人民共和国法律规定，天美服务无法进行“即时”金额转账服务，资金于途中流转的时间在合理范围内您是予以认同的。</div>
          <div>7. 您同意，天美公司无须对您使用天美服务期间由天美公司保管或代收或代付的款项的货币贬值承担任何风险，并且天美公司无须向您支付此等款项的任何孳息。</div>
          <div>8. 交易风险</div>
          <div>
            当您通过天美服务进行各项交易或接受交易款项时，若您或对方未遵从本服务条款或网站说明中的操作指示，天美服务并不保证交易会顺利完成，且天美公司不承担任何损害赔偿责任。若因上述状况致使款项已先行拨入您的账户，天美公司保留直接自您的账户余额中扣回款项及禁止您要求支付此笔款项的权利。此款项若已汇入您的银行账户，您同意天美公司有向您事后索回的权利。
          </div>
          <div>（二）一般服务条款</div>
          <div>在使用本协议项下的“天美”服务时，为便于您查询或计量您的应收或应付款，天美公司将为您提供一个唯一的编号，即账户，并由您自行设置密码后，您可通过天美公司实现以下“天美”中介服务：</div>
          <div>1. 保管功能：</div>
          <div>您可以向天美公司汇入一定金额的货币，并委托天美公司代为保管。</div>
          <div>2. 代收功能：</div>
          <div>您可以要求天美公司代为收取天美公司其它注册用户向您支付的各类合法款项。</div>
          <div>3. 代付功能：</div>
          <div>您可以要求天美公司就所保管或代收的款项向特定的第三方进行支付，您同意如非经法律程序或者非由于本条款约定事项的出现，此等支付是不可逆转的。</div>
          <div>4. 退（付）款功能：</div>
          <div>您可以要求天美公司退返所保管的您的款项或向您支付您的应收款，但是：</div>
          <div>
            1）当您收领款项时，必须提供一个与汇款人（保管款）或指定收款人（应付款）名称相符的有效的国内银行账户。当您向天美公司作出退（付）款指示时，天美公司将于收到指标后的一到十个工作日内（根据您登记的银行不同，会产生时间上的差异），将相应的资金按照您的指示汇入您登记的银行账户，除此以外本服务不提供其他收领方式。
          </div>
          <div>2）国际使用者若需使用“退（付）款”功能，则需具备国内银行账户。</div>
          <div>5. 查询功能：</div>
          <div>
            天美公司将对您在本系统中的所有操作进行记录，不管该操作的目的最终是否实现，您可以在本系统中实时进行查询，并可以此为基础与您的账户进行核对查询，如您认为数额有误，则天美公司将向您提供天美公司已按照您的指示收付款的纪录，您了解并同意您最终能够收到款项的责任是由您登记的银行账户对应的银行提供的，您需向该银行请求查证。
          </div>
          <div>6. 账户纪录：</div>
          <div>若您不使用保管于天美公司的款项，天美公司将会妥善保管您的资金，不作任何其他用途。</div>
          <div>7. 交易异常处理：</div>
          <div>1）您了解并同意于使用天美服务时，可能由于银行本身系统问题、银行相关作业网络连线问题或其他不可抗拒因素，将可能造成服务无法正常提供。</div>
          <div>
            2）您确认您所输入的个人基本资料无误，以便天美公司能于上述异常状况发生时，通知您该笔交易后续处理方式。若您所提供之基本资料有误，造成天美公司于上述异常状况发生时，无法及时通知您后续处理方式，天美公司对此将不承担任何损害赔偿责任。
          </div>
          <div>8. 您同意基于运行的需要，天美公司可以暂时提供部分服务功能，或于将来暂停部分服务功能或开通新的服务功能，当任何功能减少或者增加或者变化时，只要您仍然使用天美服务，表示您仍然同意本条款或者本条款修正后的条款。
          </div>
          <div>（三）服务费用：</div>
          <div>当您使用天美服务时，天美公司会向您收取相关服务费用。各项服务费用请详见您使用天美服务时天美网站上所列之收费方式说明。天美公司保留有订定及调整服务费之权利。</div>
          <div>您同意，除非另有说明，上述服务费用天美公司有权自天美公司代收应向您支付的款项中先行扣除。</div>
          <div>四、暂停、拒绝或终止您的使用</div>
          <div>（一）您同意天美公司有权基于单方独立判断，包含但不限于天美公司认为您已经违反本服务条款的规定，将暂停、中断或终止向您提供“天美”服务（或其任何部分），并将“天美”服务内任何“用户资料”加以移除并删除。
          </div>
          <div>（二）您同意天美公司在发现异常交易或有疑义或有违法之虞时，不经通知有权先行暂停或终止您的账户、密码，并拒绝您使用“天美”服务之部份或全部功能。</div>
          <div>（三）您同意在必要时，天美公司无需进行事先通知即得终止提供“天美”服务，并可能立即暂停、关闭或删除您的账户及您账户中所有相关资料及档案。</div>
          <div>五、用户账户、密码及安全性</div>
          <div>您了解并同意 , 确保密码及账户的机密安全是您的责任。您将对利用该密码及账户所进行的一切行动及言论，负完全的责任，并同意以下事项：</div>
          <div>（一）您不可对其他任何人泄露您的账户或密码，亦不可使用其他任何人的账户或密码。</div>
          <div>
            （二）您同意如发现有第三人冒用或盗用您的账户及密码，或其他任何未经合法授权的情形，应立即以有效方式通知天美公司，同意暂停其使用本服务并采取有效的防范措施。天美公司在接受您的有效通知前，对第三人使用该服务已发生之效力，除非可证明天美公司故意或重大过失而不知是未经合法授权之使用，否则天美公司将不承担任何责任。
          </div>
          <div>六、用户的守法义务及承诺</div>
          <div>
            您承诺绝不为任何非法目的或以任何非法方式使用本服务，也不将本服务用于禁止或限制物品的交易，并承诺遵守中华人民共和国相关法律、法规及一切使用互联网之国际惯例。您若是中华人民共和国以外之使用者，您同意同时遵守您所属国家或地域的法令。
          </div>
          <div>您同意并保证不得利用本服务从事侵害他人权益或违法之行为，若有违反者应负所有法律责任，包括但不限于：</div>
          <div>（一）反对宪法所确定的基本原则，危害国家安全、泄漏国家秘密、颠覆国家政权、破坏国家统一的。</div>
          <div>（二）侵害他人名誉、隐私权、商业秘密、商标权、著作权、专利权、其他智慧财产权及其他权利。</div>
          <div>（三） 违反依法律或合约所应负之保密义务。</div>
          <div>（四） 冒用他人名义使用本服务。</div>
          <div>（五） 从事任何不法交易行为，如贩卖枪支、毒品、禁药、盗版软件或其他违禁物。</div>
          <div>（六） 提供赌博资讯或以任何方式引诱他人参与赌博。</div>
          <div>（七） 涉嫌洗钱、套现或进行传销活动的。</div>
          <div>（八） 使用无效信用卡号码，或非使用者本人的信用卡号码进行交易。</div>
          <div>（九） 使用他人银行账户或无效银行账户交易。</div>
          <div>（十） 从事任何可能含有电脑病毒或是可能侵害本服务系統、资料之行为。</div>
          <div>（十一） 其他天美公司有正当理由认为不适当之行为。</div>
          <div>七、系统服务中断或故障</div>
          <div>系统因下列状况无法正常运作，使用户无法使用各项服务时，天美公司对您不负任何损害赔偿责任，包括但不限于：</div>
          <div>（一）天美公司系统停机维护期间。</div>
          <div>（二）电信设备出现故障不能进行数据传输的。</div>
          <div>（三）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成天美公司系统障碍不能执行业务的。</div>
          <div>八、责任范围及责任限制</div>
          <div>（一）天美公司仅对本协议中所列明的义务承担责任。</div>
          <div>（二）您明确因交易所产生的任何风险应由交易双方承担。</div>
          <div>（三）用户信息是由用户自行发布，天美公司无法保证用户信息的真实、及时和完整，您应对您的判断承担全部责任。</div>
          <div>（四）天美公司未对交易标的及“天美”服务提供任何形式的保证，包括但不限于以下事项：</div>
          <div>1. “天美”服务将符合您的需求。</div>
          <div>2. “天美”服务将不受干扰、及时提供或免于出錯。</div>
          <div>3. 您经由“天美”服务购买或取得之任何产品、服务、资讯或其他资料将符合您的期望。</div>
          <div>（五）“天美”服务的合作单位，所提供的服务品质及内容由该合作单位自行负责。</div>
          <div>（六）您经由“天美”服务的使用下载或取得任何资料，应由您自行考量且自负风险，因资料的下载而导致您电脑系统的任何损坏或资料流失，您应负完全责任。</div>
          <div>（七）您自天美公司及公司工作人员或经由“天美”服务取得的建议或资讯，无论其为书面或口头，均不构成天美公司对“天美”服务的任何保证。</div>
          <div>
            （八）在法律允许的情况下，天美公司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使其事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除天美公司对上述损失的责任。
          </div>
          <div>（九）除本协议另有规定外，在任何情况下，天美公司对本协议所承担的违约赔偿责任总额不超过向您收取的当次“天美”服务费用总额。</div>
          <div>九、知识产权的保护</div>
          <div>（一）天美网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由天美公司或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。</div>
          <div>（二）非经天美公司或其他权利人书面同意任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。</div>
          <div>（三）尊重知识产权是您应尽的义务，如有违反，您应对天美公司承担损害赔偿责任。</div>
          <div>十、服务条款的解释、法律适用及争端解决</div>
          <div>（一）天美公司对本服务条款拥有最终的解释权。</div>
          <div>（二）本协议及其修订本的有效性、履行和与本协议及其修订本效力有关的所有事宜，将受中华人民共和国法律管辖，任何争议仅适用中华人民共和国法律。</div>
          <div>（三）本协议签订地为中国深圳市。因本协议所引起的用户与天美公司的任何纠纷或争议，首先应友好协商解决，协商不成的，用户在此完全同意将纠纷或争议提交天美公司所在地即深圳市有管辖权的人民法院诉讼解决。</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
</script>
<style lang="less" scoped>
  .page-box {
    min-height: 100vh;
    background-color: #FFFFFF;
  }

  .notice-details {
    padding: 15px;

    .notice-title {
      text-align: center;
      font-size: 18px;
      color: #333333;
    }

    .time {
      text-align: right;
      font-size: 13px;
      color: #999999;
      margin: 15px 0 20px;
    }

    .goodsDetail {
      font-size: 14px;
      text-indent: 20px;
      line-height: 1.8;

      div {
        margin-bottom: 10px;
      }

      b {}
    }
  }
</style>